import {lazy} from "react";
const CmsFilters = lazy(() => import("./views/cmsFilters/CmsFilters"));
const CheckoutFilters = lazy(() => import("./widgets/CheckoutFilters"));
const CmsProductFilters = lazy(() => import("./widgets/cmsProductFilters/CmsProductFilters"));
const ShopFilters = lazy(() => import("./widgets/ShopFilters"));
const OrderFilters = lazy(() => import("./widgets/OrderFilters"));


const exports = {
    routes: [
        {
            name: "cmsFilters",
            trans: ["Filteri", "Filters"],
            path: ["filteri", "filters"],
            parent: "products.cmsProductsTab",
            layouts: ["cms.CmsLayout"],
            element: <CmsFilters />,
            menus: {cmsLeft: {position: 5}},
        }
    ],
    widgets: {
        ShopFilters: ShopFilters,
        OrderFilters: OrderFilters,
        CheckoutFilters: CheckoutFilters,
        CmsProductFilters: CmsProductFilters // Single page
    },
}
export default exports;
