import {lazy} from "react";
const BrandsCarousel = lazy(() => import("./widgets/BrandsCarousel"));
const ShopBrand = lazy(() => import("./widgets/ShopBrand"));
const ShopBrands = lazy(() => import("./widgets/ShopBrands"));
const CmsBrands = lazy(() => import("./views/cmsBrands/CmsBrands"));
const Brands = lazy(() => import("./views/brands/Brands"));
const CmsBrandsDrawer = lazy(() => import("./widgets/CmsBrandsDrawer"));

const exports = {
    routes: [
        {
            name: "cmsBrands",
            trans: ["Brendovi", "Brands"],
            path: ["brendovi", "brands"],
            parent: "products.cmsProductsTab",
            layouts: ["cms.CmsLayout"],
            element: <CmsBrands />,
            menus: {cmsLeft: {position: 3}},
        },
        {
            name: "brands",
            path: ["brendovi", "brands"],
            layouts: ["public.PublicLayout"],
            element: <Brands />,
        },
    ],
    widgets: {
        CmsBrandsDrawer: CmsBrandsDrawer,
        ShopBrand: ShopBrand,
        ShopBrands: ShopBrands,
        BrandsCarousel: BrandsCarousel,
    },
}
export default exports;
