import {lazy} from "react";
const BlocksRender = lazy(() => import(/* webpackChunkName: "BlocksRender" */ "./widgets/BlocksRender"));
const Blocks = lazy(() => import("./views/cmsDesign/Blocks"));

const exports = {
    routes: [

    ],
    widgets: {
        Blocks: Blocks,
        BlocksRender: BlocksRender
    }
}
export default exports;