import {lazy} from "react";
import usePreloadCategories from "./preloads/usePreloadCategories";
const CmsCategories = lazy(() => import("./views/cmsCategories/CmsCategories"));
const ShopCategories = lazy(() => import("./widgets/ShopCategories"));
const CategoriesCarousel = lazy(() => import("./widgets/CategoriesCarousel"));
const CategoriesDrawer = lazy(() => import("./widgets/CategoriesDrawer"));
const CmsCategoriesDrawer = lazy(() => import("./widgets/CmsCategoriesDrawer"));
const CmsProductCategories = lazy(() => import("./widgets/cmsProductCategories/CmsProductCategories"));


const exports = {
    routes: [
        {
            name: "cmsCategories",
            trans: ["Kategorije", "Categories"],
            path: ["kategorije", "categories"],
            element: <CmsCategories />,
            layouts: ["cms.CmsLayout"],
            parent: "products.cmsProductsTab",
            menus: {cmsLeft: {position: 2}},
        },
    ],
    preloads: [usePreloadCategories],
    widgets: {
        CategoriesCarousel: CategoriesCarousel,
        CmsCategoriesDrawer: CmsCategoriesDrawer,
        CategoriesDrawer: CategoriesDrawer,
        CmsProductCategories: CmsProductCategories,
        ShopCategories: ShopCategories,
    },
}
export default exports;
