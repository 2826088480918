import {lazy} from "react";
import slugify from "slugify";
import useQueryParser from "./widgets/useQueryParser";
const ProductRender = lazy(() => import("./widgets/ProductRender"));
const LoadProductsCarousel = lazy(() => import("./widgets/LoadProductsCarousel"));
const ProductsCarousel = lazy(() => import("./widgets/ProductsCarousel"));
const CmsProductDrawer = lazy(() => import("./widgets/CmsProductDrawer"));
const CmsProductsDrawer = lazy(() => import("./widgets/CmsProductsDrawer"));
const CmsProducts = lazy(() => import("./views/cmsProducts/CmsProducts"));
const Shop = lazy(() => import("./views/shop/Shop"));
const ShopDrawer = lazy(() => import("./widgets/ShopDrawer"));


const exports = {
    routes: [
        {
            name: "cmsProductsTab",
            trans: ["Proizvodi", "Products"],
            path: ["proizvodi", "products"],
            element: <CmsProducts />,
            layouts: ["cms.CmsLayout"],
            menus: {cmsLeft: {position: 10}},
        },
        {
            name: "product",
            path: ["proizvod/:productId", "product/:productId"],
            element: <Shop />,
            layouts: ["public.PublicLayout"],
            compile: (product, lang) => {
                if(!product) return "shop";
                let slug = "/" + ["proizvod", "product"][lang];
                if(product.category?.breadcrumbs)   {
                    const breadcrumbs = product.category.breadcrumbs.map(it => it.slug);
                    slug += "/" + breadcrumbs.join("/");
                }
                slug = slug + "/" + product.id + "/" + slugify(product.name);
                return slug;
            }
        },
        {name: "product1", path: ["proizvod/:productId/:slug", "product/:productId/:slug"], element: <Shop />, layouts: ["public.PublicLayout"]},
        {name: "product2", path: ["proizvod/:categorySlug/:productId/:slug", "product/:categorySlug/:productId/:slug"], element: <Shop />, layouts: ["public.PublicLayout"]},
        {name: "product3", path: ["proizvod/:param1/:categorySlug/:productId/:slug", "product/:param1/:categorySlug/:productId/:slug"], element: <Shop />, layouts: ["public.PublicLayout"]},
        {name: "product4", path: ["proizvod/:param1/:param2/:categorySlug/:productId/:slug", "product/:param1/:param2/:categorySlug/:productId/:slug"], element: <Shop />, layouts: ["public.PublicLayout"]},

        {
            name: "category",
            path: ["prodavnica", "shop"],
            element: <Shop />,
            layouts: ["public.PublicLayout"],
            compile: (category, lang) => {
                let slug = "/" + ["prodavnica", "shop"][lang];
                if(!category) return slug;
                if(!category.breadcrumbs)   return slug + "/" + category.slug;
                const breadcrumbs = category.breadcrumbs.map(it => it.slug);
                return slug + "/" + breadcrumbs.join("/");
            }
        },
        {name: "shop", path: ["prodavnica", "shop"], element: <Shop />, layouts: ["public.PublicLayout"]},
        {name: "shop1", path: ["prodavnica/:categorySlug", "shop/:categorySlug"], element: <Shop />, layouts: ["public.PublicLayout"]},
        {name: "shop2", path: ["prodavnica/:param1/:categorySlug", "shop/:param1/:categorySlug"], element: <Shop />, layouts: ["public.PublicLayout"]},
        {name: "shop3", path: ["prodavnica/:param1/:param2/:categorySlug", "shop/:param1/:param2/:categorySlug"], element: <Shop />, layouts: ["public.PublicLayout"]},

        {name: "action", path: ["akcija", "action"], element: <Shop />, layouts: ["public.PublicLayout"]},
        {name: "action1", path: ["akcija/:categorySlug", "action/:categorySlug"], element: <Shop />, layouts: ["public.PublicLayout"]},
        {name: "action2", path: ["akcija/:param1/:categorySlug", "action/:param1/:categorySlug"], element: <Shop />, layouts: ["public.PublicLayout"]},
        {name: "action3", path: ["akcija/:param1/:param2/:categorySlug", "action/:param1/:param2/:categorySlug"], element: <Shop />, layouts: ["public.PublicLayout"]},

        {name: "new", path: ["novo", "new"], element: <Shop />, layouts: ["public.PublicLayout"]},
        {name: "new1", path: ["novo/:categorySlug", "new/:categorySlug"], element: <Shop />, layouts: ["public.PublicLayout"]},
        {name: "new2", path: ["novo/:param1/:categorySlug", "new/:param1/:categorySlug"], element: <Shop />, layouts: ["public.PublicLayout"]},
        {name: "new3", path: ["novo/:param1/:param2/:categorySlug", "new/:param1/:param2/:categorySlug"], element: <Shop />, layouts: ["public.PublicLayout"]},

        {
            name: "brand",
            path: ["brend", "action"],
            element: <Shop />,
            layouts: ["public.PublicLayout"],
            compile: (brandName) => {
                if(!brandName) return "shop";
                return "/brend/" + brandName;
            }
        },
        {name: "brand1", path: ["brend/:brandName", "brand/:brand"], element: <Shop />, layouts: ["public.PublicLayout"]},
        {name: "brand2", path: ["brend/:brandName/:categorySlug", "brand/:brandName/:categorySlug"], element: <Shop />, layouts: ["public.PublicLayout"]},
        {name: "brand3", path: ["brend/:brandName/:param2/:categorySlug", "brand/:brandName/:param2/:categorySlug"], element: <Shop />, layouts: ["public.PublicLayout"]},
        {name: "brand4", path: ["brend/:brandName/:param2/:param3/:categorySlug", "brand/:brandName/:param2/:param3/:categorySlug"], element: <Shop />, layouts: ["public.PublicLayout"]},
    ],
    widgets: {
        CmsProductDrawer: CmsProductDrawer,
        CmsProductsDrawer: CmsProductsDrawer,
        ProductRender: ProductRender,
        ProductsCarousel: ProductsCarousel,
        LoadProductsCarousel: LoadProductsCarousel,
        ShopDrawer: ShopDrawer,
        useQueryParser: useQueryParser
    },
}
export default exports;