import {lazy} from "react";
const CmsUpdates = lazy(() => import("./views/CmsUpdates"));



const exports = {
    routes: [
        {
            name: "cmsUpdates",
            trans: ["Ažuriranje", "Updates"],
            path: ["azuriranje", "updates"],
            element: <CmsUpdates />,
            layouts: ["cms.CmsLayout"],
            menus: {cmsLeft: {position: 100}},
        },
    ],
    widgets: {

    },
}
export default exports;