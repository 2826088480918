import {gql} from "@apollo/client";
import {STORAGE_FRAGMENT} from "../../storage/graphql/storage";


export const BRAND_FRAGMENT = gql`
    ${STORAGE_FRAGMENT}
    fragment BrandFragment on Brand { 
        id 
        name
        slug
        position
        description
        descriptions
        image   {
            ...StorageFragment
        }
    }
`;

export const GET_BRANDS_QUERY = "getBrands";
export const GET_BRANDS = gql`
    ${BRAND_FRAGMENT}
    query($search: String, $name: String) {
        getBrands(search: $search, name: $name)  {
            ...BrandFragment
            products_count
        }
    }
`

export const UPSERT_BRAND = gql` 
    ${BRAND_FRAGMENT}
    mutation ($id: ID, $name: String, $slug: String, $image_id: ID, $description: String)  {
        upsertBrand(id: $id, name: $name, slug: $slug, image_id: $image_id, description: $description) {
            ...BrandFragment
        }
    }
`

export const DELETE_BRAND = gql`
    ${BRAND_FRAGMENT}
    mutation ($id: ID!) {
        deleteBrand(id: $id) {
            ...BrandFragment
        }
    }
`
