import React, {useContext} from "react";
import {Heart} from "react-feather";
import AppContext from "../../_app/AppContext";


function WishlistProductButton({id}) {
    const {toggle, wishlist} = useContext(AppContext);
    const fill = wishlist.includes(parseInt(id))
        ? {fill: "#d2520b", stroke: "#d2520b"}
        : {};

    return (
        <button onClick={() => toggle(id)}>
            <Heart {...fill} />
        </button>
    )
}
export default WishlistProductButton;