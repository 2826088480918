import {gql} from "@apollo/client";
import {STORAGE_FRAGMENT} from "../../storage/graphql/storage";


export const FILTER_FRAGMENT = gql`
    ${STORAGE_FRAGMENT}
    fragment FilterFragment on Filter {
        id
        name
        names
        parent_id
        position
        shop 
        display
        locked
        image {
            ...StorageFragment
        }
    }
`;


export const GET_FILTERS_QUERY = "getFilters";
export const GET_FILTERS = gql`
    ${FILTER_FRAGMENT}
    query ($ids: [ID!], $parent_id: Int, $category: [ID], $searchable: Boolean, $shop: Boolean) {
        getFilters(ids: $ids, parent_id: $parent_id, category: $category, searchable: $searchable, shop: $shop) {
            ...FilterFragment
            children {
                ...FilterFragment
                products_count
            }
        }
    } 
`

export const UPSERT_FILTER = gql`
    ${FILTER_FRAGMENT}
    mutation ($id: ID, $name: String, $names: [String], $parent_id: ID, $image_id: ID, $display: Boolean, $shop: Boolean)  {
        upsertFilter(id: $id, name: $name, names: $names, parent_id: $parent_id, image_id: $image_id, display: $display, shop: $shop) {
            ...FilterFragment
        }
    }
`
export const DELETE_FILTER = gql`
    ${FILTER_FRAGMENT}
    mutation ($id: ID!) {
        deleteFilter(id: $id) {
            ...FilterFragment
        }
    }
`
