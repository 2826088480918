import {Toaster} from "react-hot-toast";
import Router from "./Router";
import {ApolloProvider} from "@apollo/client";
import {apolloClient} from "./apolloClient";


function App() {
    const context = {lang: 0};
    const client = apolloClient();

    return (
        <ApolloProvider client={client}>
            <Toaster />

            <Router context={context} />
        </ApolloProvider>
    );
}

export default App;
