import {lazy} from "react";
const ProductDownloads = lazy(() => import("./widgets/ProductDownloads"));
const CmsDownloadsDrawer = lazy(() => import("./widgets/CmsDownloadsDrawer"));
const Downloads = lazy(() => import("./views/download/Downloads"));
const CmsDownloads = lazy(() => import("./views/cmsDownloads/CmsDownloads"));

const exports = {
    routes: [
        {
            name: "cmsDownloads",
            path: ["preuzimanje", "downloads"],
            trans: ["Download", "Download"],
            element: <CmsDownloads />,
            layouts: ["cms.CmsLayout"],
            menus: {cmsLeft: {position: 45}},
        },
        {
            name: "downloads",
            path: ["preuzimanje", "downloads"],
            element: <Downloads />,
            layouts: ["public.PublicLayout"],
        },
    ],
    widgets: {
        CmsDownloadsDrawer: CmsDownloadsDrawer,
        ProductDownloads: ProductDownloads,
    }
}
export default exports;