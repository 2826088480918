import {gql} from "@apollo/client";
import {STORAGE_FRAGMENT} from "../../storage/graphql/storage";

export const SETTING_FRAGMENT = gql`
    ${STORAGE_FRAGMENT}
    fragment SettingFragment on Setting {
        id 
        field
        value
        image {
            ...StorageFragment
        }
    }
`;
export const GET_SETTINGS_QUERY = "getSettings";
export const GET_SETTINGS = gql`
    ${SETTING_FRAGMENT}
    query {
        getSettings {
            ...SettingFragment
            values
        }
    }
`
export const UPSERT_SETTING = gql`
    ${SETTING_FRAGMENT}
    mutation UpsertSetting($id: ID, $field: String, $value: String, $image_id: ID)  {
        upsertSetting(id: $id, field: $field, value: $value, image_id: $image_id) {
            ...SettingFragment
        }
    }
`
