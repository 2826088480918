import {useState} from "react";

const loadWishlist = () =>  {
    let wishlist = localStorage.getItem("wishlist");
    if(!wishlist) return [];
    try {
        wishlist = JSON.parse(wishlist);
    } catch (e) {
        return [];
    }
    return wishlist;
}

function usePreloadWishlist(context) {
    const [wishlist, setWishlist] = useState(loadWishlist());

    const toggle = (id) => {
        id = parseInt(id);
        let newWishlist;
        if(wishlist.includes(id))   {
            newWishlist = wishlist.filter(item => item !== id);
        } else {
            newWishlist = [...wishlist, id];
        }
        setWishlist(newWishlist);
        if(newWishlist.length > 0)      localStorage.setItem("wishlist", JSON.stringify(newWishlist));
        else                            localStorage.removeItem("wishlist");
    }

    return {wishlist, toggle}
}
export default usePreloadWishlist;