import {lazy} from "react";
import {image, link} from "./widget/image";
const UpdateImage = lazy(() => import("./widget/UpdateImage"));
const Storage = lazy(() => import("./views/storage/Storage"));
const StorageDrawer = lazy(() => import("./widget/StorageDrawer"));


const exports = {
    routes: [
        {
            name: "files",
            path: ["fajlovi", "files"],
            trans: ["Fajlovi", "Files"],
            element: <Storage />,
            layouts: ["cms.CmsLayout"],
            menus: {cmsLeft: {position: 40}},
        },
    ],
    widgets: {
        image: image,
        link: link,
        StorageDrawer: StorageDrawer,
        UpdateImage: UpdateImage
    }
}
export default exports;