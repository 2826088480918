import React, {useContext} from "react";
import Ripples from "react-ripples";
import {Link} from "react-router-dom";
import {Heart} from "react-feather";
import AppContext from "../../_app/AppContext";


function WishlistButton() {
    const {route, trans, wishlist} = useContext(AppContext);

    return (
        <Ripples>
            <Link to={route("wishlist.wishlist")} className="w-full inline-block text-center relative">
                <div className="p-3">
                    <div className="relative inline-block">
                            <span style={{top: -8, right: -12}} className="absolute text-sm pointer-events-none z-10 bg-red-600 rounded-full font-bold text-white w-5 h-5 text-center">
                              {wishlist.length > 0 &&
                              <span className="inline-block bg-red-600 rounded-full w-full h-full absolute top-0 left-0"/>}
                                <div className="relative z-10 text-md"> {wishlist.length} </div>
                            </span>
                        <Heart />
                    </div>
                    {<div className="font-semibold text-xs md:hidden">{trans("omiljeno")}</div>}
                </div>
            </Link>
        </Ripples>
    )
}

export default WishlistButton;