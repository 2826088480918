import {useContext} from "react";
import AppContext from "../../../_app/AppContext";
import {widget} from "../../../modules";




function PageTemplate() {
    const {currentRoute} = useContext(AppContext);
    if(currentRoute.page?.block_code)  {
        const welcomePage = currentRoute.fullpath.includes("/");
        return <>
            {!welcomePage && widget("public.Breadcrumbs", {links: [{name: currentRoute.page?.name}]})}
            {widget("design.BlocksRender", {code: currentRoute.page?.block_code, welcomePage})}
        </>
    }

    return null
}
export default PageTemplate;