import {gql} from "@apollo/client";
import {PAGINATOR_INFO_FRAGMENT} from "../../../elements/graphql/paginatorInfo";
import {STORAGE_FRAGMENT} from "../../storage/graphql/storage";
import {BRAND_FRAGMENT} from "../../brands/graphql/brands";

export const DOWNLOAD_FRAGMENT = gql`
    fragment DownloadFragment on Download {
        id
        storage_id
        title
        titles
        brand_id
        type
        created_at
    }
`;


export const GET_DOWNLOADS_QUERY = "getDownloads";
export const GET_DOWNLOADS = gql`
    ${DOWNLOAD_FRAGMENT}
    ${STORAGE_FRAGMENT}
    ${BRAND_FRAGMENT}
    ${PAGINATOR_INFO_FRAGMENT}
    query ($first: Int, $page: Int, $search: String, $type: Int, $brand: Int) {
        getDownloads(first: $first, page: $page, search: $search, type: $type, brand: $brand) {
            data {
                ...DownloadFragment
                file {
                    ...StorageFragment
                }
                brand {
                    ...BrandFragment
                }
            }
            paginatorInfo {
                ...PaginatorInfoFragment
            }
        }
    }
`
export const CREATE_DOWNLOAD = gql`
    ${DOWNLOAD_FRAGMENT}
    mutation ($storage_id: ID!) {
        createDownload(storage_id: $storage_id) {
            ...DownloadFragment
        }
    }
`

export const DELETE_DOWNLOAD = gql`
    ${DOWNLOAD_FRAGMENT}
    mutation ($id: ID!) {
        deleteDownload(id: $id) {
           ...DownloadFragment
        }
    }
`
export const UPDATE_DOWNLOAD = gql`
    ${DOWNLOAD_FRAGMENT}
    mutation ($id: ID!, $storage_id: ID, $title: String, $brand_id: ID, $type: Int) {
        updateDownload(id: $id, storage_id: $storage_id, title: $title, brand_id: $brand_id, type: $type) {
            ...DownloadFragment
        }
    }
`