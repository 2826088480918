import AppContext from "../../_app/AppContext";
import {useContext} from "react";
import {
    ogImage,
    ogUrl,
    resetStructureData,
    seoAll,
    seoDescription,
    seoKeywords,
    seoTitle
} from "../providers/seoProvider";
import {widget} from "../../modules";



function RouteSeoWrapper({children})   {
    const {currentRoute, setting} = useContext(AppContext);
    const {category, productId, brandName} = widget("products.useQueryParser", null);

    resetStructureData();
    ogUrl();
    ogImage();
    seoAll(setting("default SEO title"), setting("default SEO keywords"), setting("default SEO description"));

    if(productId) return children;

    let title = `${brandName ? brandName + " " : ""}${category ? (category.seo_title || category.name) : ""}`;
    let description = `${brandName ? brandName + " " : ""}${category ? (category.seo_description || category.name) : ""}`;
    let keywords = `${brandName ? brandName + "," : ""}${category ? (category.seo_description || category.name) : ""}`;
    if(title) seoTitle(title);
    if(description) seoDescription(description);
    if(keywords) seoKeywords(keywords);

    if(currentRoute.page)   {
        if(currentRoute.page.seo_title) seoTitle(currentRoute.page.seo_title);
        if(currentRoute.page.seo_keywords) seoKeywords(currentRoute.page.seo_keywords);
        if(currentRoute.page.seo_description) seoDescription(currentRoute.page.seo_description);
    }
    return children;
}

export default RouteSeoWrapper;
