import {gql} from "@apollo/client";
import {STORAGE_FRAGMENT} from "../../storage/graphql/storage";


export const CATEGORY_FRAGMENT = gql`
    fragment CategoryFragment on Category { 
        id 
        name
        parent_id
        slug
        names
        slugs 
        seo_title
        seo_keywords
        seo_description
        seo_titles
        seo_keywords_all
        seo_descriptions
    }
`;

export const GET_CATEGORIES_QUERY = "getCategories";
export const GET_CATEGORIES = gql`
    ${CATEGORY_FRAGMENT}
    ${STORAGE_FRAGMENT}
    query ($search: String) {
        getCategories(search: $search) {
            ...CategoryFragment
            products_count
            image   {
                ...StorageFragment
            }
            image_2   {
                ...StorageFragment
            }
        }
    }
`

export const UPSERT_CATEGORY = gql` 
    ${CATEGORY_FRAGMENT}
    mutation ($id: ID, $name: String, $slug: String, $names: [String], $slugs: [String], $parent_id: ID, $image_id: ID,
     $seo_title: String, $seo_description: String, $seo_keywords: String, $image_id_2: ID)  {
        upsertCategory(id: $id, name: $name, slug: $slug, names: $names, slugs: $slugs, parent_id: $parent_id, image_id: $image_id,
            seo_title: $seo_title, seo_description: $seo_description, seo_keywords: $seo_keywords, image_id_2: $image_id_2) {
            ...CategoryFragment
        }
    }
`

export const DELETE_CATEGORY = gql`
    ${CATEGORY_FRAGMENT}
    mutation ($id: ID!) {
        deleteCategory(id: $id) {
            ...CategoryFragment
        }
    }
`
