import {lazy} from "react";
import {seoAll} from "./providers/seoProvider";
import RouteSeoWrapper from "./wrappers/RouteSeoWrapper";
//const Rankings = lazy(() => import("./views/rankings/Rankings"));

const exports = {
    routes: [
        /*{
           name: "seo",
           path: ["seo", "seo-en"],
           trans: ["SEO", "SEO"],
           element: <div />,
           layouts: ['cms.CmsLayout'],
           menus: {cmsLeft: {position: 15}},
       },
       {
           name: "rankings",
           path: ["rangiranje", "rankings"],
           trans: ["Rangiranje", "Rankings"],
           element: <Rankings />,
           layouts: ['cms.CmsLayout'],
           parent: "seo.seo",
           menus: {cmsLeft: {position: 1}},
       },*/
    ],
    wrappers: [RouteSeoWrapper],
    widgets: {
        seoAll: seoAll
    }
}
export default exports;