import {modulesWrappers} from "../modules";


function LoadRouteWrappers({children})  {

    //const routeWrappers = modulesWrappers.filter(item => item.name.startsWith("Route"));
    const routeWrappers = modulesWrappers;
    const wrap = (content, index=0) => {
        if(!routeWrappers[index])       {
            return content
        }
        const Wrapper = routeWrappers[index];
        return <Wrapper>
            {wrap(content, index+1)}
        </Wrapper>
    }

    return wrap(children);
}
export default LoadRouteWrappers;