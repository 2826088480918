import {Suspense} from "react";
import pagesExport from "./pages/exports";
import langExport from "./_lang/exports";
import publicExport from "./public/exports";
import settingsExport from "./_settings/exports";
import seoExport from "./_seo/exports";
import cmsExport from "./cms/exports";
import storageExport from "./storage/exports";
import brandsExport from "./brands/exports";
import categoriesExport from "./categories/exports";
import filtersExport from "./filters/exports";
import productsExport from "../modules/products/exports";
import textEditorExport from "../modules/textEditor/exports";
import designExport from "../modules/design/exports";
import wishlistExport from "../modules/wishlist/exports";
import usersExport from "../modules/users/exports";
import ordersExport from "../modules/orders/exports";
import downloadsExport from "../modules/downloads/exports";
import updatesExport from "../modules/updates/exports";
//import liveExport from "../modules/live/exports";
import blogExport from "../modules/blog/exports";
import wallExport from "../modules/wall/exports";


const modules = {
    pages: pagesExport,
    lang: langExport,
    public: publicExport,
    settings: settingsExport,
    seo: seoExport,
    cms: cmsExport,
    products: productsExport,
    storage: storageExport,
    brands: brandsExport,
    categories: categoriesExport,
    filters: filtersExport,
    textEditor: textEditorExport,
    design: designExport,
    wishlist: wishlistExport,
    users: usersExport,
    orders: ordersExport,
    //live: liveExport,
    blog: blogExport,
    wall: wallExport,
    downloads: downloadsExport,
    updates: updatesExport,
}


const defaultRoutes = {};
export const modulesRoutes = {};
export let modulesWrappers = [];
export let modulesPreloads = [];
export const menus = {};
export const widgets = {};

for (let moduleKey in modules) { // prvi level, za Layout
    const module = modules[moduleKey];
    if (module.routes) {
        for (let i = 0; i < module.routes.length; i++) {
            const route = module.routes[i];
            if (route.name) {
                defaultRoutes[`${moduleKey}.${route.name}`] = {...route, name: `${moduleKey}.${route.name}`};
            }
        }
    }
    if (module.widgets) {
        for (let widgetKey in module.widgets) {
            widgets[`${moduleKey}.${widgetKey}`] = module.widgets[widgetKey];
        }
    }
    if (module.wrappers) {
        modulesWrappers = [...modulesWrappers, ...module.wrappers];
    }
    if (module.preloads) {
        modulesPreloads = [...modulesPreloads, ...module.preloads];
    }
}

export function addToRoutes(routes) {
    for (let routerKey in routes) {
        const route = routes[routerKey];
        if (route.layouts) {
            const holderPath = [];
            const holderLayouts = [];
            for (let j = 0; j < route.layouts.length; j++) {
                if (!defaultRoutes[route.layouts[j]]) {
                    console.log(`Ne postoji layout ${route.layouts[j]}`);
                    continue;
                }
                const layout = defaultRoutes[route.layouts[j]];
                holderLayouts.push(layout);
                if (typeof layout.path === "string") {
                    for (let k = 0; k < route.path.length; k++) {
                        if (route.path[k] === null) continue;
                        const append = (layout.path.startsWith("/") || layout.path === "") ? "" : "/";
                        const path = layout.path === "/" ? "" : layout.path;
                        holderPath[k] = (holderPath[k] || "") + append + path;
                    }
                } else {
                    for (let k = 0; k < route.path.length; k++) {
                        if (route.path[k] === null) continue;
                        const append = (layout.path[k].startsWith("/") || layout.path[k] === "") ? "" : "/";
                        const path = layout.path[k] === "/" ? "" : layout.path[k];
                        holderPath[k] = (holderPath[k] || "") + append + path;
                    }
                }
            }
            for (let k = 0; k < route.path.length; k++) {
                if (route.path[k] === null) continue;
                const append = (route.path[k].startsWith("/") || (route.path[k] === "" && holderPath[k] !== ""))
                    ? "" : "/";
                const path = route.path[k] === "/" ? "" : route.path[k];
                holderPath[k] = (holderPath[k] || "") + append + path;
            }
            modulesRoutes[routerKey] = {...route, fullpath: holderPath, layouts: holderLayouts};
        }
        if (route.menus) {
            for (let menuKey in route.menus) {
                if (!menus[menuKey]) menus[menuKey] = {};
                const pos = route.menus[menuKey].position || 100;
                if (!menus[menuKey][pos]) menus[menuKey][pos] = [];
                menus[menuKey][pos].push(modulesRoutes[routerKey]);
            }
        }
    }
}

addToRoutes(defaultRoutes);


export const widget = (path, params = false, suspense = true) => {
    if (!widgets[path]) {
        console.log("MISSING: ", `Ne postoji widget: ${path}`)
        return null;
    }
    const Element = widgets[path];
    const arr = path.split(".");
    if (arr[1][0] === arr[1][0].toUpperCase()) {
        if (!suspense) {
            return <Element {...params} />
        }
        return <Suspense fallback={<div/>}>
            <Element {...params} />
        </Suspense>
    }
    if (params !== false) {
        return widgets[path](params);
    }
    return widgets[path];
}

