import {useQuery} from "@apollo/client";
import {GET_PRODUCTS, GET_PRODUCTS_QUERY} from "../../products/qraphql/product";
import {randString} from "../../../elements/helpers";
import toast from "react-hot-toast";
import {useEffect, useState} from "react";
import {Minus, Plus} from "react-feather";


const loadBasket = () =>  {
    let basket = localStorage.getItem("basket");
    if(!basket) return [];
    try {
        basket = JSON.parse(basket);
    } catch (e) {
        return [];
    }
    return basket;
}

function usePreloadBasket(context)  {
    const [load, setLoad] = useState(loadBasket());
    const {data, previousData, error} = useQuery(GET_PRODUCTS, {
        skip: load.length === 0,
        variables: {
            first: 100,
            ids: load.map(b => b.productId)
        },
        notifyOnNetworkStatusChange: true
    });
    let products = false;
    if((previousData && previousData[GET_PRODUCTS_QUERY])) products = previousData[GET_PRODUCTS_QUERY];
    if((data && data[GET_PRODUCTS_QUERY])) products = data[GET_PRODUCTS_QUERY];

    useEffect(() => {
        if(!products) return false;
        if(products.data.length === 0) return false;
        for(let i=0; i<load.length; i++) {
            const find = products.data.find(p => p.id === load[i].productId);
            if (!find) {
                const newSave = load.filter(f => f.id !== load[i].id);
                localStorage.setItem("basket", JSON.stringify(newSave));
                setLoad(newSave);
            }
        }
    }, [products.data]);

    let basket = [];
    let total = 0;

    if(products) {
        for(let i=0; i<load.length; i++)    {
            const find = products.data.find(p => p.id === load[i].productId);
            if(!find) continue;
            total += find.process_price * load[i].qty;
            basket.push({...load[i], product: find});
        }
    }

    const add = (item, qty, filters = []) => {
        if(!qty) qty = 1;
        qty = parseInt(qty);
        const insert = {productId: item.id, qty, filters, id: randString()};
        setLoad([...load, insert]);
        localStorage.setItem("basket", JSON.stringify([...load, insert]));
        toast.success("dodato");
    }
    const remove = (id) => {
        const newLoad = load.filter(item => item.id !== id);
        setLoad(newLoad);
        localStorage.setItem("basket", JSON.stringify(newLoad));
    }
    const update = (id, sbj, value) => {
        if(sbj === "qty")   {
            if(value < 1)   return false;
            if(value > 10)  return false;
        }
        const newLoad = load.map(item => {
           if(item.id === id)   {
               return {...item, [sbj]: value};
           }
            return item;
        });
        setLoad(newLoad);
        localStorage.setItem("basket", JSON.stringify(newLoad));
    }
    const empty = () => {
        setLoad([]);
        localStorage.removeItem("basket");
    }

    const Qty = ({id}) => {
        const product = basket.find(item => item.id === id);
        if(!product) return null;
        return <div className="inline-block">
            <div className="flex">
                <button onClick={() => update(id, "qty", product.qty-1)}
                        className="btn text-sm rounded-r-none"><Minus size={16} /></button>
                <div className="inline-block bg-pubdark-3 flex px-2 items-center text-white font-semibold"> {product.qty} </div>
                <button onClick={() => update(id, "qty", product.qty+1)}
                        className="btn text-sm rounded-l-none"><Plus size={16} /></button>
            </div>
        </div>
    }

    return {
        basket,
        Qty,
        addToBasket: add,
        removeFromBasket: remove,
        updateBasket: update,
        totalBasket: total,
        emptyBasket: empty
    }
}
export default usePreloadBasket;